<template>
  <div>
    <CouponHolder v-bind:couponObj="couponToUse" />
  </div>
  <div>
    <img v-if="qrUrl !== null" id="qrImage" :src="qrUrl" />
  </div>
</template>

<script>
import liff from "@line/liff";
import CouponHolder from "@/components/coupons/CouponHolder.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import * as slrCrmFunc from "../composables/slrcrm-cloud-functions";
import { firestore } from "../firebase/config";
import QRCode from "qrcode";
export default {
  name: "MemberUseCoupon",
  components: {
    CouponHolder,
  },

  data() {
    return {qrCodeTicket: null, qrUrl: null, unsub: null };
  },
  // props: { couponId: String },
  computed: {
    ...mapGetters(["couponToUse"]),
  },

  created() {
    // this.generateQRTicket();
  },

  mounted() {
    if(this.couponToUse.id) {
      this.generateQRTicket();
    }
  },

  unmounted() {
    if (this.unsub instanceof Function) {
      this.unsub();
    }
  },

  methods: {

    ...mapActions(['getCoupons']),

    renderQRCode() {
      this.$Loading.update(60);
      QRCode.toDataURL(this.qrCodeTicket.id, {
        errorCorrectionLevel: "L",
        version: 5,
      })
        .then((url) => {
          console.log(url);
          this.qrUrl = url;
          this.$Loading.finish();
        })
        .catch((err) => {
          console.error(err);
          this.$Loading.finish();
        });
    },

    async updatePointAndRelocate() {
      this.$Loading.update(40);
      await this.getCoupons(liff.getIDToken());
      this.$Loading.update(80);
      this.$Loading.finish();
      this.$router.push({ name: "MemberCouponList" });
    },

    generateQRTicket() {
      this.$Loading.start();
      const objective = {qrFor: 'couponUse', ref: this.couponToUse.id};
      this.unsub = slrCrmFunc
        .generateCustomerOneTimeQRCode(liff.getIDToken(), objective)
        .then((resData) => {
          console.log("Ticket", resData);
          this.qrCodeTicket = resData;
          this.$Loading.update(20);
          this.unsub = firestore
            .collection("/SLRCRM/BKLCRM/Triggers/")
            .where("unqId", "==", resData.data.customerUid)
            .onSnapshot((querySnapshot) => {
              console.log("Subscriber Current data: ", querySnapshot);
              querySnapshot.docChanges().forEach((change) => {
                if (change.type == "modified") {
                  this.$Loading.start();
                  this.unsub();
                  this.$Loading.update(20);
                  this.updatePointAndRelocate();
                }
              });
            });
          this.renderQRCode();
        })
        .catch((e) => {
          console.log("Error", e);
        });
    },
  },
};
</script>

<style scoped></style>
