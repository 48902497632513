<template>
  <div>
    <CouponFreeKanom :onClickedHandle="onCouponClick" v-bind:couponObj="couponObj" v-if="getCouponType === '5'"/>
    <Coupon250B :onClickedHandle="onCouponClick"  v-bind:couponObj="couponObj" v-else-if="getCouponType === '10'" />
  </div>
</template>

<script>
import Coupon250B from '@/components/coupons/Coupon250B.vue';
import CouponFreeKanom from '@/components/coupons/CouponFreeKanom.vue';
export default {
  name: "Test",
  created() {},
  data() {
    return {isUseObject: true, couponType: '0'};
  },

  components:{
    CouponFreeKanom, Coupon250B,
  },

  props: {couponObj: Object, onClicked: Function},
  
  computed: {
    getCouponType() {
      if(this.couponObj) {
        this.isUseObject = true;
        this.couponType = this.couponObj.data.type;
        console.log('A:', this.couponType);
        return this.couponType;
      }else{
        console.log('C:', this.couponType);
        return '0';
      }
    }
    
  },
  
  methods: {
    onCouponClick() {
      if(this.onClicked) {
        if(this.isUseObject) {
          this.onClicked(this.couponObj.id);
        }else{
          //disable onClicked
          return;
        }
      }
    },

    getCouponObjectByUid(couponUid) {

    }
  },
};
</script>

<style scoped>
</style>