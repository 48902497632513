<template>
  <span id="mainHelper"></span>
  <div id="main">
    <div id="divProfilePhoto">
      <span class="helper"></span>
      <img id="imgProfilePhoto" :src="picUrl" />
      <!-- <img id="imgProfilePhoto" /> -->
      <span id="spanName">{{ name }}</span>
    </div>
    <div class="form">
      <!-- <form @submit.prevent="requestOTP"> -->
      <label>เบอร์โทรศัพท์</label>
      <input type="tel" required v-model="tel" :disabled="!isEnableInputTel" />
      <div>
        <span class="validationFail">{{ validationTel }}</span>
      </div>
      <label>OTP {{ countDownOTP }}</label>
      <input type="number" required v-model="otpPin" :disabled="!isEnableInputPin" />
      <div>
        <span class="validationFail">{{ validationOtp }}</span>
      </div>
      <div class="summit">
        <button @click="onOTPButtonClick">{{ getButtonOTPText }}</button>
      </div>
      <!-- </form> -->
    </div>
  </div>
  {{ updateMobile }}
</template>

<script>
import { reactive, ref } from "vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import liff from "@line/liff";
import { LIFF_CHANNEL_ID, LIFF_ID } from "../line/line-config";
import * as slrCrmFunc from "../composables/slrcrm-cloud-functions";
import QRCode from "qrcode";

export default {
  name: "MemberRegister",
  components: {},
  data() {
    return {
      customer: null,
      qrCodeTicket: null,
      picUrl: require("@/assets/profilepic.png"),
      name: "Loading",
      tel: null,
      otpPin: null,
      qrUrl: null,
      isRequestedOTP: false,
      buttonOTPText: "ขอ OTP",
      isEnableInputTel: true,
      isEnableInputPin: false,
      validationOtp: null,
      validationTel: null,
      countDownOTP: null,
      countdownIntervalId: null,
    };
  },

  computed: {
    ...mapGetters(["customer"]),

    updateMobile() {
      if (this.customer && this.customer.data && this.customer.data.mobile) {
        this.isRequestedOTP = true;
        this.tel = this.customer.data.mobile;
      }
    },

    getButtonOTPText() {
      if (this.isRequestedOTP) {
        return "ยืนยัน OTP";
      } else {
        return "ขอ OTP";
      }
    },
  },

  created() {
    this.fetchLineProfile();
    this.checkIsWaitingOTP()
  },

  methods: {
    fetchLineProfile() {
      let idToken = liff.getIDToken();
      const res = slrCrmFunc
        .updateLineProfileToDBByLineIdToken(idToken)
        .then((res) => {
          this.picUrl = res.data.picture + "/large";
          this.name = res.data.name;
          this.customer = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    onOTPButtonClick() {
      if (!this.isRequestedOTP) {
        var phonePattern = /^\d{10}$/;
        if (this.tel.match(phonePattern)) {
          this.validationTel = null;
          this.requestOTP();
        } else {
          this.validationTel = "รูปแบบหมายเลขโทรศัพไม่ถูกต้อง พิมพ์ตัวเลขเบอร์โทร 10 หลักเท่านั้น";
        }
      } else {
        this.verifyOTPPin();
      }
    },

    requestOTP() {
      this.$Loading.start();
      this.$Loading.update(30);
      this.isRequestedOTP = true;
      this.isEnableInputTel = false;
      this.isEnableInputPin = false;
      slrCrmFunc
        .sendOTP(liff.getIDToken(), this.tel)
        .then((isSuccess) => {
          if (isSuccess) {
            this.isEnableInputPin = true;
            this.checkIsWaitingOTP();
            this.$Loading.finish();
          } else {
            this.isRequestedOTP = false;
            this.isEnableInputTel = true;
            this.isEnableInputPin = false;
            this.$Loading.finish();
          }
        })
        .catch((e) => {
          this.isRequestedOTP = false;
          this.isEnableInputTel = true;
          this.isEnableInputPin = false;
          this.$Loading.finish();
          console.log(e);
        });
    },

    verifyOTPPin() {
      this.validationOtp = null;
      this.isEnableInputTel = false;
      this.isEnableInputPin = false;
      this.$Loading.start();
      this.$Loading.update(30);
      slrCrmFunc.verifyOTP(liff.getIDToken(), this.otpPin).then((isSuccess) => {
        if (isSuccess) {
          this.$Loading.finish();
          console.log(isSuccess);
          location.reload();
          // this.$router.push({ path: '/' });
        } else {
          this.$Loading.finish();
          this.isEnableInputTel = false;
          this.isEnableInputPin = true;
          this.validationOtp = "รหัสยืนยัน OTP ไม่ถูกต้อง กรุณาลองใหม่";
        }
      });
    },

    checkIsWaitingOTP() {
      //{isWaiting: isWaiting, tokenRequestTimestamp: tokenRequestTimestamp};
      slrCrmFunc
        .isWaitingOTP(liff.getIDToken())
        .then((isWaitingResult) => {
          if (isWaitingResult.isWaiting) {
            this.isEnableInputTel = false;
            this.isEnableInputPin = true;
            this.startCountdownOTP(isWaitingResult);
          } else {
            this.isEnableInputTel = true;
            this.isEnableInputPin = false;
          }
        })
        .catch((e) => {
          this.isEnableInputTel = true;
          this.isEnableInputPin = false;
          console.log(e);
        });
    },

    startCountdownOTP(isWaitingResult) {
      this.countdownIntervalId = setInterval(() => {
        const now = new Date();
        const remainder = 120 - Math.floor((now.getTime() - isWaitingResult.tokenRequestTimestamp) / 1000);
        this.countDownOTP = remainder;
        if (remainder <= 0) {
          clearInterval(this.countdownIntervalId);
          this.countDownOTP = null;
          this.isRequestedOTP = false;
          this.buttonOTPText = "ขอ OTP";
          this.isEnableInputTel = true;
          this.isEnableInputPin = false;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
#mainHelper {
  display: inline-block;
  text-align: center;
}
#main {
  position: relative;
  width: 100%;
  /* background-color: lightgoldenrodyellow;
  width: 100%;
  height: 100px;
  border-radius: 10px; */
}

#divProfilePhoto {
  /* position: relative;
  margin: 5px 5px;
  width: 100%;
  height: 100%;
  background-size: 75px 75px;
  text-align: left;
  background: wheat; */

  max-width: 100%;
  margin: 5px 5px;
  background: wheat;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}

#imgProfilePhoto {
  background: #3a6f9a;
  vertical-align: middle;
  max-height: 75px;
  max-width: 75px;
  display: inline;
  border-radius: 10px;
}

/* #spanName {
  display: inline;
} */

#spanName {
  display: inline-block;
  text-align: center;
  width: min-content;
  margin-left: 20px;
  font-style: normal;
  font-weight: bold;
  color: peru;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-left: 20px;
}

div.form {
  max-width: 100%;
  margin: 5px 5px;
  background: wheat;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
  text-align: center;
}

button {
  background: #0b6dff;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
}
.submit {
  text-align: center;
}

span.validationFail {
  color: red;
}
</style>
