import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

//nam5
const firebaseConfig = {
  apiKey: "AIzaSyAU_KiVAlI3xivP06EzxXQe3SjxzPZW94o",
  authDomain: "bkltestpoc.firebaseapp.com",
  databaseURL: "https://bkltestpoc.firebaseio.com",
  projectId: "bkltestpoc",
  storageBucket: "bkltestpoc.appspot.com",
  messagingSenderId: "360625767519",
  appId: "1:360625767519:web:bdd4642e18023f6ed747ea",
};


//Tokyo
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBhIlemzukopQMQ2ZqZx1XALqdtW5Tn-2o",
//   authDomain: "bklproduction-asia-northeast1.firebaseapp.com",
//   projectId: "bklproduction-asia-northeast1",
//   storageBucket: "bklproduction-asia-northeast1.appspot.com",
//   messagingSenderId: "602749160270",
//   appId: "1:602749160270:web:dfc6a3a49e9084a4d52c1f",
//   measurementId: "G-K9HVCXEV0H"
// };


//Jakata
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyBLNxX_rIfzyZLlXFveGKdNnA2Gg5aSYlc",
//   authDomain: "bklproduction-asia-southeast2.firebaseapp.com",
//   projectId: "bklproduction-asia-southeast2",
//   storageBucket: "bklproduction-asia-southeast2.appspot.com",
//   messagingSenderId: "18088979203",
//   appId: "1:18088979203:web:3da1f4a8535e9648141736",
//   measurementId: "G-9G554XSJVG"
// };

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
let functionsTemp = null;


if (process.env.NODE_ENV === "development") {
  console.log('development mode');
  functionsTemp = firebase.functions();
  functionsTemp.useEmulator('localhost', 25001);
  firestore.useEmulator('localhost', 28080);
} else {
  console.log('production mode');
  // functionsTemp = firebase.app().functions("asia-northeast1");
  functionsTemp = firebase.app().functions();
}

const functions = functionsTemp;

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

//
// functions.useEmulator('localhost', 25001);
// firestore.useEmulator('localhost', 28080);
//


export {firestore, functions, timestamp};
