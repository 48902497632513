<template>
  <div id="nav">
    <!-- customer && customer.id !== null -->
    <span v-if="data.showNavAll">
      <router-link to="/MemberHome">Home</router-link>
    </span>
    <!-- <span v-if="data.showNavAll && data.navShow.register">
      |
      <router-link to="/register">ลงทะเบียน</router-link>
    </span> -->
    <span v-if="data.showNavAll && data.navShow.getPoint">
      |
      <router-link :to="{ name: 'MemberGetPoint', params: { title: 'รับแต้ม' } }">รับแต้ม</router-link>
    </span>
    <span v-if="data.showNavAll && data.navShow.coupon">
      |
      <router-link to="/couponList">คูปอง</router-link>
    </span>
    <!-- <span v-if="data.showNavAll && data.navShow.pointTransfered">
      |
      <router-link to="/pointTransfer">โอนแต้ม</router-link>
    </span> -->
  </div>
  <router-view />
  <div>
    <button @click="lineLogin">Line Login</button>
    <button @click="lineLogout">Line Logout</button>
  </div>
  {{ renderNav }}
  <div>
    <button @click="sendOTP">Send SMS</button>
  </div>
</template>

<script>
import liff from "@line/liff";
import { LIFF_CHANNEL_ID, LIFF_ID } from "../src/line/line-config";
// import * as slrCrmFunc from "./composables/slrcrm-cloud-functions";
import { mapActions, mapGetters } from "vuex";
import * as slrCrmFunc from "./composables/slrcrm-cloud-functions";
export default {
  data() {
    return {
      data: {
        showNavAll: false,
        navShow: {
          register: false,
          getPoint: false,
          coupon: false,
        },
      },
    };
  },

  computed: {
    ...mapGetters(["customer", "coupons"]),
    renderNav() {
      if (this.customer != null && this.customer.data != null) {
        if (this.customer.data.registered && this.customer.data.isPassPointTransferedProcess) {
          this.data.showNavAll = true;
        } else {
          this.data.showNavAll = false;
        }

        this.data.navShow.register = !this.customer.data.registered;
        this.data.navShow.getPoint = this.customer.data.registered;
        this.data.navShow.coupon = this.customer.data.registered;
      } else {
        this.data.showNavAll = false;
      }
    },
  },

  created() {
    liff
      .init({ liffId: LIFF_ID })
      .then((res) => {
        if (liff.isLoggedIn()) {
          const idToken = liff.getIDToken();
          this.updateLineProfileToDBByLineIdToken(idToken).then(() => {
            // this.getCoupons(liff.getIDToken()).then(() => {
            if (this.customer && this.customer.data && this.customer.data.registered && this.customer && this.customer.data && this.customer.data.isPassPointTransferedProcess) {
              this.$router.push({ name: "MemberHome" });
            } else if (this.customer && this.customer.data && !this.customer.data.registered) {
              this.$router.push({ name: "MemberRegister" });
            } else if (this.customer && this.customer.data && this.customer.data.registered && this.customer && this.customer.data && !this.customer.data.isPassPointTransferedProcess) {
              //case registered but not transferPointProcess
              this.$router.push({ name: "MemberTransferPoint" });
            } else {
              //impossible case
              console.log("Impossible state case App.vue");
            }
            // });
          });
        } else {
          liff.login();
        }
      })
      .then(console.log("finish"));
  },

  methods: {
    ...mapActions(["updateLineProfileToDBByLineIdToken", "getCoupons"]),

    lineLogin() {
      liff.login();
    },

    lineLogout() {
      liff.logout();
      location.reload();
    },

    sendOTP() {
      slrCrmFunc.addPointForLineAddFriendFirsttimeIfNeed(liff.getAccessToken(), liff.getIDToken())
        .then(response => {
          console.log("friendShip:", response);
        });

      // slrCrmFunc.sendOTP();

      // liff.getFriendship().then((data) => {
      //   console.log("Is Friend:", data.friendFlag);
      //   if (data.friendFlag) {
      //     // something you want to do
        
      //   }
      // });
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
