<template>
  <div class="bg" @click="onCouponClick">
    <img class="photo" src="@/assets/profilepic.png" alt="" />
    <span class="description">ครบ 5 ดวง ฟรีขนม มูลค่าไม่เกิน 65 บาท 1 ชิ้น</span>
    <br />
    <span class="dateGet">{{ getCreatedDate }}</span>
  </div>
</template>

<script>
export default {
  name: "CouponFreeKanom",
  created() {},
  data() {
    return {};
  },
  computed: {
    getCreatedDate() {
      if(!this.couponObj)
        return 'Coupon not found!';
      let date = new Date(this.couponObj.data.created._seconds * 1000);
        return date.toLocaleString("th-TH", {
          weekday: "short", // "Sat"
          month: "long", // "June"
          day: "2-digit", // "01"
          year: "numeric", // "2019"
          hourCycle: "h24",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
    },
  },
  props: { couponObj: Object, onClickedHandle: Function},
  methods: {
    onCouponClick() {
      this.onClickedHandle(this.couponObj);
    },

  },
};
</script>

<style scoped>
div.bg {
  padding-top: 20px;
  background: lightskyblue;
  width: auto;
  height: 200px;
  margin: 10px 10px 10px 10px;

  /* background: linear-gradient(lightskyblue 49%, transparent 0%), linear-gradient(-45deg, white 33%, transparent 0%) 0 50%, white linear-gradient(45deg, white 33%, lightskyblue 33%) 0 50%;
  background-repeat: repeat-x;
  background-size: 1px 100%, 40px 40px, 40px 40px; */


/* background: 
linear-gradient(135deg, #42a5e2 25%, transparent 25%) -10px 0,
linear-gradient(225deg, #42a5e2 25%, transparent 25%) -10px 0,
linear-gradient(315deg, #42a5e2 25%, transparent 25%),
linear-gradient(45deg, #42a5e2 25%, transparent 25%);	
background-size: 20px 20px;
background-color: lightskyblue; */

color: white;
background-color:#66a3ff;
 
background-image: repeating-linear-gradient(transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),  
  repeating-linear-gradient(270deg, transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px);

}


img.photo {
  padding-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border-radius: 50%;
}

span.description {
  margin-top: 30px;
  display: block;
  color: white;
  font-weight: bold;
}

span.dateGet {
  margin-top: 10px;
  display: block;
  color: white;
}
</style>
