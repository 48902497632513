<template>
  <div class="bg" @click="onCouponClick">
    <img class="photo" src="@/assets/profilepic.png" alt="" />
    <span class="description">ครบ 10 ดวง รับคูปองแทนเงินสด 250 บาท</span>
    <br />
    <span class="dateGet">{{ getCreatedDate }}</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CouponFreeKanom",
  data() {
    return {};
  },
  props: { couponObj: Object, onClickedHandle: Function},
  methods: {
    onCouponClick() {
      this.onClickedHandle(this.couponObj);
    },

  },
  computed: {
    getCreatedDate() {
      if(!this.couponObj)
        return 'Coupon not found!';
      let date = new Date(this.couponObj.data.created._seconds * 1000);
        return date.toLocaleString("th-TH", {
          weekday: "short", // "Sat"
          month: "long", // "June"
          day: "2-digit", // "01"
          year: "numeric", // "2019"
          hourCycle: "h24",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
    },
  },
};
</script>

<style scoped>
div.bg {
  padding-top: 20px;
  background: lightskyblue;
  width: auto;
  height: 200px;
  margin: 10px 10px 10px 10px;

  color: white;
  background-color: #f84747;

  background-image: repeating-linear-gradient(transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0, 0, 0, 0.5) 116px, rgba(0, 0, 0, 0.5) 166px, rgba(255, 255, 255, 0.2) 166px, rgba(255, 255, 255, 0.2) 169px, rgba(0, 0, 0, 0.5) 169px, rgba(0, 0, 0, 0.5) 179px, rgba(255, 255, 255, 0.2) 179px, rgba(255, 255, 255, 0.2) 182px, rgba(0, 0, 0, 0.5) 182px, rgba(0, 0, 0, 0.5) 232px, transparent 232px), repeating-linear-gradient(270deg, transparent, transparent 50px, purple 50px, purple 53px, transparent 53px, transparent 63px, purple 63px, purple 66px, transparent 66px, transparent 116px, rgba(0, 0, 0, 0.5) 116px, rgba(0, 0, 0, 0.5) 166px, rgba(255, 255, 255, 0.2) 166px, rgba(255, 255, 255, 0.2) 169px, rgba(0, 0, 0, 0.5) 169px, rgba(0, 0, 0, 0.5) 179px, rgba(255, 255, 255, 0.2) 179px, rgba(255, 255, 255, 0.2) 182px, rgba(0, 0, 0, 0.5) 182px, rgba(0, 0, 0, 0.5) 232px, transparent 232px);
}

div.topHelper {
  display: block;
  margin-top: 30px;
}

img.photo {
  padding-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  border-radius: 50%;
}

span.description {
  margin-top: 30px;
  display: block;
  color: white;
  font-weight: bold;
}

span.dateGet {
  margin-top: 10px;
  display: block;
  color: white;
}
</style>
