<template>
  <!-- <div>
        <button @click="genQRClick">GenQR</button>
    </div> -->
  <!-- <it-button @click="$Loading.update(50)">Set 50%</it-button> -->
  <div><Label>{{title}}</Label></div>
  <div>
    <!-- <it-loading color="#f93155"></it-loading> -->
    <img id="qrImage" :src="qrUrl" />
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import Vue from "vue";
import liff from "@line/liff";
import * as slrCrmFunc from "../composables/slrcrm-cloud-functions";
import { firestore } from "../firebase/config";
import QRCode from "qrcode";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "MemberGetPoint",
  components: {},
  props: { title: null },
  data() {
    return { qrCodeTicket: null, qrUrl: null, unsub: null };
  },

  created() {
    // this.testVuex();
    this.generateQRTicket();
  },

  updated() {},

  unmounted() {
    if (this.unsub instanceof Function) {
      this.unsub();
    }
  },

  computed: {
    ...mapGetters(["customer", "pointData"]),
  },

  methods: {
    ...mapActions(["setIsShowNewPoint", "updateLineProfileToDBByLineIdToken", "getPointDataByCustomerUid"]),

    async updatePointAndRelocate() {
      this.$Loading.update(40);
      await this.getPointDataByCustomerUid(this.customer.id);
      this.$Loading.update(80);
      await this.setIsShowNewPoint(true);
      this.$Loading.finish();
      this.$router.push({ name: "MemberHome" });
    },

    generateQRTicket() {
      this.$Loading.start();
      const objective = { qrFor: "pointGet", ref: null };
      this.unsub = slrCrmFunc
        .generateCustomerOneTimeQRCode(liff.getIDToken(), objective)
        .then((resData) => {
          this.qrCodeTicket = resData;
          this.$Loading.update(20);
          this.unsub = firestore
            .collection("/SLRCRM/BKLCRM/Triggers/")
            .where("unqId", "==", resData.data.customerUid)
            .onSnapshot((querySnapshot) => {
              console.log("Subscriber Current data: ", querySnapshot);
              querySnapshot.docChanges().forEach((change) => {
                if (change.type == "modified") {
                  this.$Loading.start();
                  this.unsub();
                  this.$Loading.update(20);
                  this.updatePointAndRelocate();
                }
              });
            });
          this.renderQRCode();
        })
        .catch((e) => {
          console.log("Error", e);
        });
    },
    renderQRCode() {
      // console.log("HAYTYyYYY");
      this.$Loading.update(60);
      QRCode.toDataURL(this.qrCodeTicket.id, {
        errorCorrectionLevel: "L",
        version: 5,
      })
        .then((url) => {
          console.log(url);
          this.qrUrl = url;
          this.$Loading.finish();
        })
        .catch((err) => {
          console.error(err);
          this.$Loading.finish();
        });
    },
  },
};
</script>

<style scoped>
#qrImage {
  min-width: 180px;
  min-height: 180px;
}

label {
  color: brown;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
</style>
