import { firestore, functions, timestamp } from "../firebase/config";
import { liff } from "@line/liff";


export const updateLineProfileToDBByLineIdToken = async (idToken) => {
  const result = functions.httpsCallable("updateLineProfileToDBByLineIdToken");
  const response = await result(idToken);
  const resData = response.data;
  return resData;
};

export const generateCustomerOneTimeQRCode = async (lineTokenId, objective) => {
  const params = {lineIdToken: lineTokenId, qrFor: objective.qrFor, ref: objective.ref};
  const result = functions.httpsCallable("generateCustomerOneTimeQRCode");
  const response = await result(params);
  const resData = response.data;
  return resData;
};

//return {currentPoint: 0, lastPoint: 0}
export const getPointDataByCustomerUid = async (customerUid) => {
  const result = functions.httpsCallable("getPointDataByCustomerUid");
  const response = await result(customerUid);
  return response.data;
}

export const getCouponsByLineIdToken = async (lineIdToken) => {
  const result = functions.httpsCallable("getCouponsByLineIdToken");
  const couponArray = await result(lineIdToken);
  return couponArray;
}

export const getCouponByUid = async (lineIdToken, couponUid) => {
  const result = functions.httpsCallable("getCouponByUid");
  const params = {lineIdToken: lineIdToken, couponUid:couponUid};
  const coupon = await result(params);
  return coupon;
}

export const sendOTP = async (lineIdToken, mobileNo) => {
  const params = {mobileNo: mobileNo, lineIdToken: lineIdToken};
  const result = functions.httpsCallable("sendOTP");
  const res = await result(params);
  return res.data;//true or false
}

export const verifyOTP = async (lineIdToken, otpPin) => {
  const params = {lineIdToken: lineIdToken, pin: otpPin};
  const result = functions.httpsCallable("verifyOTP");
  const res = await result(params);
  return res.data; //true or false
}

export const isWaitingOTP = async (lineIdToken) => {
  const params = {lineIdToken: lineIdToken};
  const result = functions.httpsCallable("isWaitingOTP");
  const res = await result(params);
  return res.data; 
  //{isWaiting: isWaiting, tokenRequestTimestamp: tokenRequestTimestamp};
}

export const setIsPassPointTransferedProcess = async (lineIdToken, isPass) => {
  const params = {lineIdToken: lineIdToken, IsPassPointTransferedProcess: isPass};
  const result = functions.httpsCallable("setIsPassPointTransferedProcess");
  const res = await result(params);
  return res.data;
}

export const addPointForLineAddFriendFirsttimeIfNeed = async (lineAccessToken, lineIdToken) => {
  const params = {lineIdToken: lineIdToken, lineAccessToken: lineAccessToken};
  const result = functions.httpsCallable("addPointForLineAddFriendFirsttimeIfNeed");
  const res = await result(params);
  return res.data;
}

export default { updateLineProfileToDBByLineIdToken, generateCustomerOneTimeQRCode };
