<template>
  <h1></h1>
  <div class="home">
    <MemberCard :showNewPoint="showNewPoint" />
  </div>
  <div class="coupon">
    <button class="coupon" ref="couponButton">ดูบัตรแลกของรางวัลที่ได้รับ<span class="couponCount">{{coupons.length}}</span></button>
  </div>
</template>

<script>
// @ is an alias to /src
import liff from "@line/liff";
import MemberCard from "@/components/MemberCard.vue";
import { mapActions, mapGetters } from "vuex";
// import { useRoute } from 'vue-router'

// const route = useRoute()
// console.log(route.query)
export default {
  name: "MemberHome",
  components: {
    MemberCard,
  },

  data() {
    return {
      showNewPoint: this.$route.query.showNewPoint,
    };
  },

  created() {},
  computed: {
    ...mapGetters(["customer", "coupons"]),
  },

  mounted() {
      if(this.showNewPoint === 'true') {
          this.updateCustomer(liff.getIDToken());
      }
      this.getCoupons(liff.getIDToken());
  },

  updated() {
  },

  methods: {
    ...mapActions(["updateLineProfileToDBByLineIdToken", "getCoupons"]),

    updateCustomer(lineTokenId) {
      this.updateLineProfileToDBByLineIdToken(lineTokenId);
    },

    // async getCoupons() {
    //   await this.getCoupons(liff.getIDToken());
    //   console.log(this.coupons);
    //   this.$refs.couponButton.innerHTML = "ดูบัตรแลกของรางวัลที่ได้รับ (" + this.coupons.lenght + ')';
    // }
  },
};
</script>

<style scoped>
.home {
  position: relative;
}

div.coupon {
  margin: 10px 5px 0px 5px;
}

span.couponCount {
  margin-left: 5px;
  padding: 5px;
  border-radius: 50%;
  background: #972b00;
}

button.coupon {
  background: #ca3a00;
  border: 0;
  padding: 0px 0px 0 0;
  margin: 0px 0px 0px 0px;
  color: white;
  border-radius: 5px;
  width: 100%;
  height: 50px;
}
</style>
